<template>
  <div id="MenuPageIndex" class="menu-page">
    <div class="text-center fontsize-h3 font-headblue weight-700 mb-5">
      ระบบประเมินคุณภาพระบบประปาหมู่บ้าน
    </div>

    <div class="row justify-content-center menu-select mx-auto row w-75">
      <div class="col-4">
        <div class="">
          <img
            src="/images/menu1.jpeg"
            class="img-menu cursor-pointer"
            @click="gotoStatusPage"
          />
        </div>
        <div
          class="box-menu bg-1 fontsize-h3 position-relative cursor-pointer"
          @click="gotoStatusPage"
        >
          ตรวจสอบสถานะ <br />
          การตอบแบบประเมิน
          <div class="btn-circle shadow">
            <iconify icon="bx:bxs-chevron-right" class="font-deepblue mb-4" />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="">
          <img
            src="/images/menu2.jpg"
            class="img-menu cursor-pointer"
            @click="gotoDashboardPage"
          />
        </div>
        <div
          class="box-menu bg-2 fontsize-h3 position-relative cursor-pointer"
          @click="gotoDashboardPage"
        >
          รายงานผลการประเมิน <br />
          (Dashboard)
          <div class="btn-circle shadow">
            <iconify icon="bx:bxs-chevron-right" class="font-deepblue mb-4" />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="">
          <img
            src="/images/menu3.jpg"
            class="img-menu cursor-pointer"
            @click="gotoUserPage"
          />
        </div>
        <div
          class="box-menu bg-3 fontsize-h3 position-relative cursor-pointer"
          @click="gotoUserPage"
        >
          การจัดการผู้ใช้งาน
          <div class="btn-circle shadow">
            <iconify icon="bx:bxs-chevron-right" class="font-deepblue mb-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-page",
  methods: {
    gotoStatusPage() {
      this.$router.push({ name: "CheckStatusIndex" });
    },
    gotoDashboardPage() {
      this.$router.push({ name: "ReportIndex" });
    },
    gotoUserPage() {
      this.$router.push({ name: "UserList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-select {
  max-width: 80%;
  min-width: 600 px;
  margin: auto;
  text-align: center;
}
</style>
